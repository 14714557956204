<template >
    <div class="countdown-timer">
        <countdown-timer-pulse v-if="days > 0" :pulseText="days" :labelText="__('interface.days')"/>
        <countdown-timer-pulse v-if="days > 0 || hours > 0" :pulseText="hours" :labelText="__('interface.hours')"/>
        <countdown-timer-pulse v-if="days > 0 || hours > 0 || minutes > 0" :pulseText="minutes" :labelText="__('interface.minutes')"/>
        <countdown-timer-pulse :pulseText="seconds" :labelText="__('interface.seconds')"/>
    </div>
</template>


<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import CountdownTimerPulse from './CountdownTimerPulse.vue';

export default {
    name: "TsCountdownTimer",
    components: {CountdownTimerPulse},
    props: {
        endDate: {
            required: true,
        }
    },
    data () {
        dayjs.extend(utc)
        const distance = dayjs(this.endDate).utc().diff(dayjs().utc(), 'second');
        return {
            distance: distance,
            seconds: this.countSeconds(distance),
            minutes: this.countMinutes(distance),
            hours: this.countHours(distance),
            days: this.countDays(distance)
        }
    },
    watch: {
        distance: {
            handler (value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.distance--
                        this.days = this.countDays(this.distance)
                        this.hours = this.countHours(this.distance)
                        this.minutes = this.countMinutes(this.distance)
                        this.seconds = this.countSeconds(this.distance)
                    }, 1000)
                }
            },
            immediate: true
        }
    },
    methods: {
        countSeconds(distance) {
            return Math.floor((distance % (60)))
        },
        countMinutes(distance) {
            return Math.floor((distance % (60 * 60)) / (60))
        },
        countHours(distance) {
            return Math.floor((distance % (60 * 60 * 24)) / (60 * 60))
        },
        countDays(distance) {
            return Math.floor(distance / (60 * 60 * 24))
        }
    }
}
</script>

<style lang="scss" scoped>
    .countdown-timer {
        display: flex;
        justify-content: center;

        > * {
            margin-left: 6px;
            margin-right: 6px;
        }
    }
</style>