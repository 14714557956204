<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'SessionIndex'})"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ message.name }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="content" :fullscreen="true">
      <div>
        <img :src="logoSrc" alt="The Spott" height="120">
      </div>
      <div>
        <div v-if="message.status === 'SESSION_DOES_NOT_EXIST'">
          <h3>{{ __('interface.session-not-exist') }}</h3>
        </div>
        <div v-if="message.status === 'SESSION_FUTURE'">
          <template v-if="!loadingState">
            <template v-if="!isCountdownFinished">
              <h3>{{ __('interface.session-will-start-in') }}</h3>
              <countdown-timer :endDate="message.startDate"/>
            </template>
            <template v-else>
              <h3>{{ __('interface.session-cannot-join') }}</h3>
              <span>{{ __('interface.session-waiting-for-trainer') }}</span>
            </template>
          </template>
        </div>
        <div v-if="message.status === 'SESSION_CANCELLED'">
          <h3>{{ __('interface.session-cannot-join') }}</h3>
          <span>{{ __('interface.session-cancelled-by-owner') }}</span>
        </div>
        <div v-if="message.status === 'SESSION_ENDED'">
          <h3>{{ __('interface.session-cannot-join') }}</h3>
          <span>{{ __('interface.session-ended') }}</span>
        </div>
        <div v-if="message.status === 'SESSION_IN_PROGRESS'">
          <h3>{{ __('interface.session-in-progress') }}</h3>
          <ion-button @click="openVideoConference">{{ __('interface.session-join') }}</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, loadingController, toastController} from '@ionic/vue'
import {API, apiConstants} from "@/utils/apiWrapper"
import AuthView from "@/views/AuthService";
import {layout} from "@/utils/layout";
import CountdownTimer from '@/components/CountdownTimer.vue';
import dayjs from 'dayjs'
import {Plugins} from '@capacitor/core'
import {mapActions, mapState} from 'vuex'
import {Storage} from '@capacitor/storage';

const {TwilioCapacitor} = Plugins

export default {
  name: 'VideoConferenceWaitingRoom',
  mixins: [AuthView, layout],
  components: {CountdownTimer, IonPage},
  data() {
    return {
      message: {},
      interval: null,
      isCountdownFinished: false,
      autoConnectInterval: null,
      loadingState: false
    }
  },
  computed: {
    sessionId() {
      return this.$route.params.sessionId
    },
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    ...mapActions({
      startNotification: 'ble/startNotification',
      stopNotification: 'ble/stopNotification',
      reconnect: 'ble/reconnect'
    }),
    async openVideoConference() {
      const authHeader = await apiConstants.getAuthorization();
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      })
      await loading.present()
      API.post('TheSpottApi', '/twiliotokengenerate', {
        body: {
          sessionId: this.sessionId
        }
      }).then(async response => {
        const data = {
          accessToken: response.data.token,
          roomName: this.sessionId,
          user: JSON.stringify(this.user),
          ownerId: this.$route.params.ownerId,
          endpoint: apiConstants.endpoint,
          authHeader: authHeader,
          sessionId: Number(this.sessionId),
          muteAll: response.data.mute_all,
          env: process.env.VUE_APP_ENV_NAME,
          sentryDsnIos: process.env.VUE_APP_SENTRY_URL_IOS,
          sentryLogs: this.$store.state.featureFlags['sentry_logs'] ?? false,
        }
        await this.startNotification();
        TwilioCapacitor.connect(data)
        await loading.dismiss()
        this.$tracking.event({
          eventName: 'joined-session',
          category: 'session'
        });
      })
    },
    sessionCanJoinCustomer() {
      API.get('TheSpottApi', '/session-can-join-customer', {
        queryStringParameters: {
          sessionId: this.sessionId,
          contextDate: this.$route.params.contextDate
        }
      }).then((response) => {
        if (response.data.startDate && response.data.nowDate) {
          const startDate = dayjs(response.data.startDate)
          const nowDate = dayjs(response.data.nowDate)

          this.isCountdownFinished = (nowDate.isSame(startDate) || nowDate.isAfter(startDate))
        }

        this.message = response.data
      }).finally(() => {
        this.loadingState = false;
      })
    },
    async getTicks() {
      const sessionId = this.sessionId
      const storage = await Storage.get({key: `ticks-data-${sessionId}`})
      let ticks = []

      if (storage.value && storage.value.length > 0) {
        ticks = JSON.parse(storage.value)
      }

      return ticks;
    }
  },
  mounted() {
    TwilioCapacitor.addListener('leave', async response => {
      const sessionId = this.sessionId
      const ticks = await this.getTicks()

      const body = {
        ticks,
        date: dayjs().toISOString(),
        sessionId,
        userAge: this.user.age,
        userWeight: this.user.weight_in_kg,
        userRestingHeartRate: this.user.resting_heart_rate,
      }

      this.$tracking.event({
        eventName: 'ticks-sending',
        category: 'session',
        event: body
      });

      await API.post('TheSpottApi', '/tick-create', {body}).then(async () => {
        await Storage.set({key: `ticks-data-${sessionId}`, value: JSON.stringify([])})

        this.$tracking.event({
          eventName: 'ticks-sent',
          category: 'session',
          event: body
        });
      })

      await this.stopNotification();
      clearInterval(this.autoConnectInterval)
      this.autoConnectInterval = null

      if ([53001, 53002, 53405].includes(response.disconnectErrorCode)) {
        const toast = await toastController.create({
          message: this.__('interface.check-your-internet-connection'),
          duration: 2000
        })
        await toast.present();
      }

      if ([53205].includes(response.disconnectErrorCode)) {
        const toast = await toastController.create({
          message: this.__('interface.someone-joined-the-session-from-another-device'),
          duration: 2000
        })
        await toast.present();
      }

      if ([53118].includes(response.disconnectErrorCode)) {
        const toast = await toastController.create({
          message: this.__('interface.trainer-disconnected'),
          duration: 2000
        })
        await toast.present();
      }

      if (response.leaveViewIsStats) {
        await this.$router.push({
          name: 'SessionSummary',
          params: {
            id: this.sessionId,
            contextDate: this.$route.params.contextDate
          }
        })
      }
      this.$tracking.event({
        eventName: 'left-session',
        category: 'session'
      });
    });

    TwilioCapacitor.addListener('deviceDisconnected', () => {
      this.autoConnectInterval = setInterval(async () => {
        if (this.$store.state.ble.connectedDevice === null) {
          await this.reconnect();
        } else {
          clearInterval(this.autoConnectInterval)
          this.autoConnectInterval = null
        }
      }, 5000)
      this.$tracking.event({
        eventName: 'device-disconnected',
        category: 'session'
      });
    })

    TwilioCapacitor.addListener('addTick', async data => {
      const ticks = await this.getTicks()
      const timestamp = dayjs().toISOString().split('.')[0] + '.000Z'

      ticks.push({
        timestamp: timestamp,
        heartRate: data.heartRate,
        deltaCalories: data.deltaCalories,
        heartRatePercentage: data.heartRatePercentage,
      })

      const sessionId = this.sessionId
      await Storage.set({key: `ticks-data-${sessionId}`, value: JSON.stringify(ticks)})
    });
  },
  ionViewWillEnter() {
    this.loadingState = true;
    this.sessionCanJoinCustomer();
    this.interval = setInterval(() => {
      this.sessionCanJoinCustomer()
    }, 5000)
  },
  ionViewDidLeave() {
    if (this.interval !== null) {
      clearInterval(this.interval)
      this.interval = null
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin-top: 4rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
