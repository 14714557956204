<template>
  <div class="container">
      <div class="pulse-text">{{pulseText}}</div>
      <div class="label-text">{{labelText}}</div>
  </div>
</template>

<script>
export default {
    props: {
        pulseText: {
            required: true,
        },
        labelText: {
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
    .pulse-text {
        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--ion-color-primary-contrast);
        background-color: var(--ion-color-secondary);
        width: 50px;
        height: 50px;
        border-radius: 25px;
        font-size: 1.8rem;
    }
    .label-text {
        font-size: 1rem;
    }
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>